import React, { useState, useEffect } from "react";
import "./style.css";

const generateCards = () => {
  const symbols = ["🐱", "🐶", "🐰", "🐼", "🐯", "🦊", "🐻", "🐸"];
  const duplicatedSymbols = [...symbols, ...symbols];
  return duplicatedSymbols.sort(() => Math.random() - 0.5);
};

const Game = () => {
  const [cards, setCards] = useState(generateCards());
  const [flippedIndices, setFlippedIndices] = useState([]);
  const [matchedPairs, setMatchedPairs] = useState([]);

  const handleCardClick = (index) => {
    if (flippedIndices.length === 2) {
      return;
    }

    if (flippedIndices.length === 1 && flippedIndices[0] === index) {
      return;
    }

    setFlippedIndices((prevFlipped) => [...prevFlipped, index]);
  };

  useEffect(() => {
    if (flippedIndices.length === 2) {
      const [firstIndex, secondIndex] = flippedIndices;

      if (cards[firstIndex] === cards[secondIndex]) {
        setMatchedPairs((prevMatched) => [...prevMatched, cards[firstIndex]]);
      }

      setTimeout(() => {
        setFlippedIndices([]);
      }, 1000);
    }
  }, [flippedIndices, cards]);

  const resetGame = () => {
    setCards(generateCards());
    setFlippedIndices([]);
    setMatchedPairs([]);
  };

  return (
    <div className="Game">
      <h1>Memory Card Game</h1>
      <div className="cards-container">
        {cards.map((symbol, index) => (
          <div
            key={index}
            className={`card ${
              flippedIndices.includes(index) || matchedPairs.includes(symbol)
                ? "flipped"
                : ""
            }`}
            onClick={() => handleCardClick(index)}
          >
            {flippedIndices.includes(index) || matchedPairs.includes(symbol)
              ? symbol
              : "❓"}
          </div>
        ))}
      </div>
      <button onClick={resetGame} className="btnprimary2">
        Reset Game
      </button>
    </div>
  );
};

export default Game;
