import React from "react";
import "./style.css";

const Wrapper = () => {
  const data = [
    {
      cover: <i class="fa-solid fa-truck-fast"></i>,
      title: "Worldwide Delivery",
      decs: "Explore unbeatable prices across our 100 million+ product range.",
    },
    {
      cover: <i class="fa-solid fa-id-card"></i>,
      title: "Safe Payment",
      decs: "Secure transactions for worry-free shopping experiences.",
    },
    {
      cover: <i class="fa-solid fa-shield"></i>,
      title: "Shop With Confidence ",
      decs: "Confidence in every purchase – shop with assurance.",
    },
    {
      cover: <i class="fa-solid fa-headset"></i>,
      title: "24/7 Support ",
      decs: "Reliable assistance around the clock – our 24/7 support ensures you're never alone in your shopping journey.",
    },
  ];
  return (
    <>
      <section className="wrapper background">
        <div className="container display-in-two">
          {data.map((val, index) => {
            return (
              <div className="product-wrapper" key={index}>
                <div className="img icon-circle">
                  <i>{val.cover}</i>
                </div>
                <h3>{val.title}</h3>
                <p>{val.decs}</p>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default Wrapper;
