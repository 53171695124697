import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SampleNextArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="control-btn" onClick={onClick}>
      <button className="next">
        <i className="fa fa-long-arrow-alt-right"></i>
      </button>
    </div>
  );
};
const SamplePrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="control-btn" onClick={onClick}>
      <button className="prev">
        <i className="fa fa-long-arrow-alt-left"></i>
      </button>
    </div>
  );
};
const FlashCard = ({ productItems, addToCart, heartIcons }) => {
  const [slidesToShow, setSlidesToShow] = useState(5);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setSlidesToShow(2);
      } else {
        setSlidesToShow(5);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 2,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  const history = useHistory();

  const handleClick = (e, shopItem) => {
    e.preventDefault();
    history.push({
      pathname: "/products-details",
      state: { shopItem },
    });
    if (!e) {
      var e = window.event;
      e.cancelBubble = true;
    }
    if (e.stopPropagation) {
      e.stopPropagation();
    }
  };

  return (
    <>
      <Slider {...settings}>
        {productItems.map((productItems) => {
          const originalPrice = parseFloat(productItems.price);

          const formattedPrice1 = originalPrice.toLocaleString("en-US", {
            style: "currency",
            currency: "KES",
            minimumFractionDigits: 2,
          });
          const formattedPrice = formattedPrice1
            .replace(/KES/g, "")
            .replace(/\.00$/, "")
            .trim();
          return (
            <div
              className="box cursor-pointer"
              onClick={(e) => handleClick(e, productItems)}
            >
              <div className="product mtop">
                <div className="img">
                  <span className="discount">
                    {(
                      ((Number(productItems.wasprice) -
                        Number(productItems.price)) /
                        Number(productItems.wasprice)) *
                      100
                    ).toFixed(0)}
                    % Off
                  </span>
                  <img
                    className=""
                    src={productItems.cover}
                    alt=""
                    style={{ width: "100%" }}
                  />
                  <div className="product-like">
                    <i
                      className={`${
                        heartIcons[productItems.id] || "fa-regular"
                      } fa-heart`}
                      onClick={(e) => addToCart(productItems, e)}
                    ></i>
                  </div>
                </div>
                <div className="product-details">
                  <h3>
                    {productItems.name.length > 50
                      ? `${productItems.name.slice(0, 50)}...`
                      : productItems.name}
                  </h3>
                  <div className="rate">
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                  </div>
                  <div className="price">
                    <h4>Ksh. {formattedPrice} </h4>
                    {/* <button onClick={(e) => addToCart(productItems, e)}>
                      <i className="fa fa-plus"></i>
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </>
  );
};

export default FlashCard;
