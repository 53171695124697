import React, { useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./common/header/Header";
import Pages from "./pages/Pages";
import Data from "./components/Data";
import Cart from "./common/Cart/Cart";
import Products from "./components/products/Products";
import Comingsoon from "./components/comingsoon/Comingsoon";
import ProductsDetails from "./components/productdetails/ProductsDetails";
import Footer from "./common/footer/Footer";
import Sdata from "./components/shops/Sdata";
import { toast } from "react-toastify";
import ScrollToTop from "./components/ScroolTop";
import Contact from "./components/contacts/Contact";
import NotFound from "./components/notfount/NotFound";
import TermsAndConditions from "./components/termsandcondations/TermsAndCondations";
import PrivacyPolicy from "./components/termsandcondations/PrivacyPolicy";
import Game from "./components/game/Game";

function App() {
  const { productItems } = Data;
  const { shopItems } = Sdata;

  const AllProducts = productItems.concat(shopItems);

  const [CartItem, setCartItem] = useState([]);

  const [heartIcons, setHeartIcons] = useState({});

  const addToCart = (product, e) => {
    e.preventDefault();
    const productExit = CartItem.find((item) => item.id === product.id);
    if (productExit) {
      setCartItem(
        CartItem.map((item) =>
          item.id === product.id
            ? { ...productExit, qty: productExit.qty + 1 }
            : item
        )
      );
      toast.info("Product quantity added");
    } else {
      setCartItem([...CartItem, { ...product, qty: 1 }]);
      toast.success("Product added to favourites");
    }
    setHeartIcons((prevIcons) => ({
      ...prevIcons,
      [product.id]:
        prevIcons[product.id] === "fa-regular" ? "fa-solid" : "fa-solid",
    }));
    if (!e) {
      var e = window.event;
      e.cancelBubble = true;
    }
    if (e.stopPropagation) {
      e.stopPropagation();
    }
  };

  const decreaseQty = (product) => {
    const productExit = CartItem.find((item) => item.id === product.id);

    if (productExit.qty === 1) {
      setCartItem(CartItem.filter((item) => item.id !== product.id));
    } else {
      setCartItem(
        CartItem.map((item) =>
          item.id === product.id
            ? { ...productExit, qty: productExit.qty - 1 }
            : item
        )
      );
      toast.success("Product quantity reduced");
    }
  };

  const removeFromCart = (product) => {
    setCartItem(CartItem.filter((item) => item.id !== product.id));
    toast.success("Product removed from Favorites");
  };
  return (
    <>
      <Router>
        <Header CartItem={CartItem} productItems={productItems} />
        <ScrollToTop />
        <Switch>
          <Route path="/" exact>
            <Pages
              productItems={productItems}
              addToCart={addToCart}
              shopItems={shopItems}
              heartIcons={heartIcons}
            />
          </Route>
          <Route path="/cart" exact>
            <Cart
              CartItem={CartItem}
              addToCart={addToCart}
              decreaseQty={decreaseQty}
              removeFromCart={removeFromCart}
            />
          </Route>
          <Route path="/products" exact>
            <Products
              productItems={productItems}
              addToCart={addToCart}
              shopItems={AllProducts}
              heartIcons={heartIcons}
            />
          </Route>
          <Route path="/products-details" exact>
            <ProductsDetails
              productItems={productItems}
              addToCart={addToCart}
              shopItems={shopItems}
              heartIcons={heartIcons}
            />
          </Route>
          <Route path="/contact" exact>
            <Contact />
          </Route>
          <Route path="/termsandcondations" exact>
            <TermsAndConditions />
          </Route>
          <Route path="/policy" exact>
            <PrivacyPolicy />
          </Route>
          <Route path="/comingsoon" exact>
            <Comingsoon />
          </Route>
          <Route path="/game" exact>
            <Game />
          </Route>
          <Route>
            <NotFound />
          </Route>
        </Switch>
        <Footer />
      </Router>
    </>
  );
}

export default App;
