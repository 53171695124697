import React from "react";
import { useHistory } from "react-router-dom";

const ShopCart = ({ shopItems, addToCart, heartIcons }) => {
  const history = useHistory();

  const handleClick = (e, shopItem) => {
    e.preventDefault();
    history.push({
      pathname: "/products-details",
      state: { shopItem },
    });
    if (!e) {
      var e = window.event;
      e.cancelBubble = true;
    }
    if (e.stopPropagation) {
      e.stopPropagation();
    }
  };

  return (
    <>
      {shopItems.map((shopItem, index) => {
        const originalPrice = parseFloat(shopItem.price);

        const formattedPrice1 = originalPrice.toLocaleString("en-US", {
          style: "currency",
          currency: "KES",
          minimumFractionDigits: 2,
        });
        const formattedPrice = formattedPrice1
          .replace(/KES/g, "")
          .replace(/\.00$/, "")
          .trim();
        return (
          <div
            className="general-product-card"
            onClick={(e) => handleClick(e, shopItem)}
          >
            <div className="box">
              <div className="product mtop">
                <div className="img">
                  <span className="discount">
                    {(
                      ((Number(shopItem.wasprice) - Number(shopItem.price)) /
                        Number(shopItem.wasprice)) *
                      100
                    ).toFixed(0)}
                    % Off
                  </span>
                  <img src={shopItem.cover} alt="" />
                  <div className="product-like">
                    <i
                      className={`${
                        heartIcons[shopItem.id] || "fa-regular"
                      } fa-heart`}
                      onClick={(e) => addToCart(shopItem, e)}
                    ></i>
                  </div>
                </div>
                <div className="product-details">
                  <h3>
                    {shopItem.name.length > 50
                      ? `${shopItem.name.slice(0, 50)}...`
                      : shopItem.name}
                  </h3>
                  <div className="rate">
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                  </div>
                  <div className="price">
                    <h4>Ksh. {formattedPrice} </h4>
                    {/* <button onClick={(e) => addToCart(shopItem, e)}>
                      <i className="fa fa-plus"></i>
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ShopCart;
