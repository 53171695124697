import React from "react";
import Home from "../components/MainPage/Home";
import FlashDeals from "../components/flashDeals/FlashDeals";
import TopCate from "../components/top/TopCate";
import Shop from "../components/shops/Shop";
import Wrapper from "../components/wrapper/Wrapper";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Pages = ({
  productItems,
  addToCart,
  CartItem,
  shopItems,
  heartIcons,
}) => {
  return (
    <>
      <Home CartItem={CartItem} />
      <FlashDeals
        productItems={productItems}
        addToCart={addToCart}
        heartIcons={heartIcons}
      />
      <TopCate />
      <Shop
        shopItems={shopItems}
        addToCart={addToCart}
        heartIcons={heartIcons}
      />
      <Wrapper />
      <ToastContainer />
    </>
  );
};

export default Pages;
