import React from "react";
import "./Home.css";
import SliderHome from "./Slider";
import { useHistory } from "react-router-dom";

const Home = () => {
  const data = [
    {
      cateImg:
        "https://res.cloudinary.com/dxxhtz2dw/image/upload/v1702714239/samples/nonex%20computers/categories/cat1_izrrvk.png",
      cateName: "Hp Laptops",
    },
    {
      cateImg:
        "https://res.cloudinary.com/dxxhtz2dw/image/upload/v1702714243/samples/nonex%20computers/categories/cat2_zlargg.png",
      cateName: "MacBook Laptops",
    },
    {
      cateImg:
        "https://res.cloudinary.com/dxxhtz2dw/image/upload/v1702714247/samples/nonex%20computers/categories/cat3_iljo1j.png",
      cateName: "Lenovo Laptops",
    },
    {
      cateImg:
        "https://res.cloudinary.com/dxxhtz2dw/image/upload/v1702714251/samples/nonex%20computers/categories/cat4_wxwqhs.png",
      cateName: "Dell Laptops",
    },
    {
      cateImg:
        "https://res.cloudinary.com/dxxhtz2dw/image/upload/v1702714253/samples/nonex%20computers/categories/cat5_h3yyxp.png",
      cateName: "Acer Computers",
    },
    {
      cateImg:
        "https://res.cloudinary.com/dxxhtz2dw/image/upload/v1702714255/samples/nonex%20computers/categories/cat6_eqrzl8.png",
      cateName: "ChromeBook Laptops",
    },
    {
      cateImg:
        "https://res.cloudinary.com/dxxhtz2dw/image/upload/v1702714257/samples/nonex%20computers/categories/cat7_ljiite.png",
      cateName: "Health & Beauty",
    },
    {
      cateImg:
        "https://res.cloudinary.com/dxxhtz2dw/image/upload/v1702714260/samples/nonex%20computers/categories/cat8_xuttta.png",
      cateName: "Others",
    },
  ];
  const history = useHistory();

  const handleClick = (e, cateName) => {
    e.preventDefault();
    history.push({
      pathname: "/products",
      state: { cateName },
    });
    if (!e) {
      var e = window.event;
      e.cancelBubble = true;
    }
    if (e.stopPropagation) {
      e.stopPropagation();
    }
  };
  return (
    <>
      <section className="home">
        <div className="home-container d_flex">
          <div className="category small-screen-display-none">
            {data.map((value, index) => {
              return (
                <div
                  className="box f_flex"
                  key={index}
                  onClick={(e) => handleClick(e, value.cateName)}
                >
                  <img className="category-images" src={value.cateImg} alt="" />
                  <span>{value.cateName}</span>
                </div>
              );
            })}
          </div>
          <SliderHome />
        </div>
      </section>
    </>
  );
};

export default Home;
