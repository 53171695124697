import React from "react";
import "./style.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="footer-grid2 footer-container">
          <div className="footer-box">
            <div className="logo width logo-name">
              <Link to="/">
                <img
                  src="https://res.cloudinary.com/dxxhtz2dw/image/upload/v1702717167/samples/nonex%20computers/logo/favicon_fnojib.png"
                  style={{ width: "50px" }}
                  alt=""
                />
              </Link>
              <Link to="/">
                <div className="name-wrapper">
                  <h1>NONEX</h1>
                  <p style={{ color: "#fff", marginTop: 0 }}>computers</p>
                </div>
              </Link>
            </div>
            <p>
              Welcome to "Nonex Computers," your premier destination for
              state-of-the-art computing solutions. Located in the heart of the
              Thika city, our modern and inviting store offers a carefully
              curated selection of the latest laptops from leading brands,
              catering to diverse needs such as productivity, gaming, and
              everyday use.
            </p>
            <div className="icon d_flex">
              <div className="img d_flex">
                <Link to="/comingsoon" style={{ color: "#fff" }}>
                  <i class="fa-brands fa-google-play"></i>
                  <span>Google Play</span>
                </Link>
              </div>
              <div className="img d_flex">
                <Link to="/comingsoon" style={{ color: "#fff" }}>
                  <i class="fa-brands fa-app-store-ios"></i>
                  <span>App Store</span>
                </Link>
              </div>
            </div>
          </div>

          <div className="footer-box">
            <h2>About Us</h2>
            <ul>
              <Link to="/cart" style={{ color: "#fff" }}>
                <li>Your Favourites</li>
              </Link>

              <Link to="/products" style={{ color: "#fff" }}>
                <li>Our Stores</li>
              </Link>

              <Link to="/contact" style={{ color: "#fff" }}>
                <li>Our Cares</li>
              </Link>

              <Link to="/termsandcondations" style={{ color: "#fff" }}>
                <li>Terms & Conditions</li>
              </Link>

              <Link to="/policy" style={{ color: "#fff" }}>
                <li>Privacy Policy</li>
              </Link>
            </ul>
          </div>
          <div className="footer-box">
            <h2>Specializations</h2>
            <ul>
              <Link to="/comingsoon" style={{ color: "#fff" }}>
                <li>Laptops</li>
              </Link>

              <Link to="/comingsoon" style={{ color: "#fff" }}>
                <li>Computers</li>
              </Link>

              <Link to="/comingsoon" style={{ color: "#fff" }}>
                <li>Printers</li>{" "}
              </Link>

              <Link to="/comingsoon" style={{ color: "#fff" }}>
                <li>Accesories</li>
              </Link>

              <Link to="/comingsoono" style={{ color: "#fff" }}>
                <li>Others</li>
              </Link>
            </ul>
          </div>
          <div className="footer-box">
            <h2>Contact Us</h2>
            <ul>
              <li>
                Tabby House, groundfloor, Commercial Street, Thika Town, Kenya.
              </li>
              <li>
                Email:{" "}
                <a href="mailto:info@nonexcomputers.com">
                  <label style={{ color: "#fff", cursor: "pointer" }}>
                    info@nonexcomputers.com
                  </label>
                </a>
              </li>
              <li>
                Phone:{" "}
                <a href="tel:+254702039048">
                  <label style={{ color: "#fff", cursor: "pointer" }}>
                    +254 702 039 048
                  </label>
                </a>
              </li>
              <li>
                WhatsApp:{" "}
                <a href="https://api.whatsapp.com/send?phone=254702039048&text=Hello%20Nonex%20computers...,%20">
                  <label style={{ color: "#fff", cursor: "pointer" }}>
                    +254 702 039 048
                  </label>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="brand-advert">
          <p>
            © Build and maintained by
            <a
              href="https://api.whatsapp.com/send?phone=254712012113&text=Hello%20Sam,%20I%20liked%20your%20website."
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#fff", marginLeft: "5px" }}
            >
              +254 712 012 113
            </a>
          </p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
