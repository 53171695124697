import React, { useEffect, useState } from "react";
import ShopCart from "./ShopCart";
import "./style.css";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

const Shop = ({ addToCart, shopItems, heartIcons }) => {
  const data = [
    {
      cateImg: "./images/category/cat1.png",
      cateName: "Hp Laptops",
    },
    {
      cateImg: "./images/category/cat2.png",
      cateName: "MacBook Laptops",
    },
    {
      cateImg: "./images/category/cat3.png",
      cateName: "Lenovo Laptops",
    },
    {
      cateImg: "./images/category/cat4.png",
      cateName: "Dell Laptops",
    },
    {
      cateImg: "./images/category/cat5.png",
      cateName: "Acer Computers",
    },
    {
      cateImg: "./images/category/cat6.png",
      cateName: "ChromeBook Laptops",
    },
    {
      cateImg: "./images/category/cat7.png",
      cateName: "Health & Beauty",
    },
    {
      cateImg: "./images/category/cat8.png",
      cateName: "Others",
    },
  ];
  const history = useHistory();

  const handleClick = (e, cateName) => {
    e.preventDefault();
    history.push({
      pathname: "/products",
      state: { cateName },
    });
    if (!e) {
      var e = window.event;
      e.cancelBubble = true;
    }
    if (e.stopPropagation) {
      e.stopPropagation();
    }
  };
  const itemsPerPage = 6;
  const [currentPage, setCurrentPage] = useState(1);
  const [shuffledShopItems, setShuffledShopItems] = useState([]);

  useEffect(() => {
    // Shuffle the items only on component mount
    const shuffledArray = shuffleArray(shopItems);
    setShuffledShopItems(shuffledArray);
  }, [shopItems]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentProducts = shuffledShopItems.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Shuffle function to randomize the array
  const shuffleArray = (array) => {
    let shuffledArray = array.slice();
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [
        shuffledArray[j],
        shuffledArray[i],
      ];
    }
    return shuffledArray;
  };

  return (
    <>
      <section className="shop background">
        <div className="container d_flex smallest-container">
          <div className="category small-screen-display-none">
            {data.map((value, index) => {
              return (
                <div
                  className="box f_flex"
                  key={index}
                  onClick={(e) => handleClick(e, value.cateName)}
                >
                  <img className="category-images" src={value.cateImg} alt="" />
                  <span>{value.cateName}</span>
                </div>
              );
            })}
          </div>

          <div className="contentWidth">
            <div className="heading d_flex">
              <div className="heading-left row  f_flex">
                <h2>Featured Products</h2>
              </div>
              <div className="heading-right row ">
                <Link to="/products">
                  <span className="color-brown">View all</span>
                  <i className="fa-solid fa-caret-right"></i>
                </Link>
              </div>
            </div>
            <div className="product-content  grid1">
              <ShopCart
                addToCart={addToCart}
                shopItems={currentProducts}
                heartIcons={heartIcons}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Shop;
