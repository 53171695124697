import React from "react";
import "./style.css";
import { ToastContainer } from "react-toastify";

const Cart = ({ CartItem, addToCart, decreaseQty, removeFromCart }) => {
  const totalPrice = CartItem.reduce(
    (price, item) => price + item.qty * item.price,
    0
  );

  const originalPrice = parseFloat(totalPrice);

  const formattedPrice1 = originalPrice.toLocaleString("en-US", {
    style: "currency",
    currency: "KES",
    minimumFractionDigits: 2,
  });
  const formattedPrice = formattedPrice1
    .replace(/KES/g, "")
    .replace(/\.00$/, "")
    .trim();

  return (
    <>
      <section className="cart-items">
        <div className="container cart-flex">
          <div className="cart-details">
            {CartItem.length === 0 && (
              <>
                <h1 className="no-items product">
                  No Items are add in Favourites
                </h1>
              </>
            )}
            {CartItem.map((item) => {
              const productQty = item.price * item.qty;
              const originalPrice = parseFloat(productQty);

              const formattedPrice1 = originalPrice.toLocaleString("en-US", {
                style: "currency",
                currency: "KES",
                minimumFractionDigits: 2,
              });
              const formattedPrice = formattedPrice1
                .replace(/KES/g, "")
                .replace(/\.00$/, "")
                .trim();

              const originalPrice2 = parseFloat(item.price);

              const formattedPrice2 = originalPrice2.toLocaleString("en-US", {
                style: "currency",
                currency: "KES",
                minimumFractionDigits: 2,
              });
              const formattedPrice3 = formattedPrice2
                .replace(/KES/g, "")
                .replace(/\.00$/, "")
                .trim();
              return (
                <div className="cart-list product d_flex" key={item.id}>
                  <div className="img">
                    <img src={item.cover} alt="" />
                  </div>
                  <div className="cart-details">
                    <h3>{item.name}</h3>
                    <h4>
                      Ksh{formattedPrice3}.00 * {item.qty}
                      <span>Ksh{formattedPrice}.00</span>
                    </h4>
                  </div>
                  <div className="cart-items-function">
                    <div className="removeCart">
                      <button
                        className="removeCart"
                        onClick={() => removeFromCart(item)}
                      >
                        <i className="fa-solid fa-xmark"></i>
                      </button>
                    </div>
                    <div className="cartControl d_flex">
                      <button
                        className="incCart"
                        onClick={(e) => addToCart(item, e)}
                      >
                        <i className="fa-solid fa-plus"></i>
                      </button>
                      <button
                        className="desCart"
                        onClick={() => decreaseQty(item)}
                      >
                        <i className="fa-solid fa-minus"></i>
                      </button>
                    </div>
                  </div>
                  <div className="cart-item-price"></div>
                </div>
              );
            })}
          </div>

          <div className="cart-total product">
            <h2>Favourites Summary</h2>
            <div className=" d_flex">
              <h4>Total Price :</h4>
              <h3>Ksh{formattedPrice}.00</h3>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </>
  );
};

export default Cart;
