const Tdata = [
  {
    cover:
      "https://res.cloudinary.com/dchwvteoz/image/upload/v1703068387/nonex%20computers/shops/uacngzxbdnuqo8yfar48.jpg",
    para: "Hp Laptops",
    desc: "30 orders this month",
  },
  {
    cover:
      "https://res.cloudinary.com/dchwvteoz/image/upload/v1702977933/nonex%20computers/shops/tmpvljyuuges6l0pgeo3.jpg",
    para: "Lenovo Laptops",
  },
  {
    cover:
      "https://res.cloudinary.com/dchwvteoz/image/upload/v1702972483/nonex%20computers/shops/btgxpzdkoxugvapgoznc.jpg",
    para: "Hp Laptops",
  },
  {
    cover:
      "https://res.cloudinary.com/dchwvteoz/image/upload/v1702977245/nonex%20computers/shops/aqlxedekw7tvps4ju71b.jpg",
    para: "Dell Laptops",
  },
  {
    cover:
      "https://res.cloudinary.com/dchwvteoz/image/upload/v1702975935/nonex%20computers/shops/ultytqsze7zena0oohhq.jpg",
    para: "Hp Laptops",
  },
];

export default Tdata;
