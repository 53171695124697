const Sdata = [
  {
    id: 5,
    title: "Empowering Tomorrow, Today: Welcome to Nonex Computers.",
    desc: "Step into a world of innovation and limitless possibilities with Nonex Computers. Where Innovation Meets Excellence. Welcome to Limitless Possibilities",
    cover:
      "https://res.cloudinary.com/dxxhtz2dw/image/upload/v1702714579/samples/nonex%20computers/slidecat/slide-5_zd06mu.png",
  },
  {
    id: 1,
    title: "10% Off For Your First Shopping",
    desc: "Asus E510M-Intel Celeron-256GB SSD-4GB RAM-Windows 11-15.6 inch Display Asus E510 Move with style Life is all about finding the right balance, and ASUS E510 delivers just that.",
    cover:
      "https://res.cloudinary.com/dxxhtz2dw/image/upload/v1702714572/samples/nonex%20computers/slidecat/slide-1_hfh46y.png",
  },
  {
    id: 2,
    title: "12% Off For Your First Shopping",
    desc: "Generic 7-inch Touch Control Laptop Quad-core Office Learning RAM 8G+ ROM 128G Laptop  Control Laptop Quad-core Office Learning RAM 8G+ ROM 128G Laptop.",
    cover:
      "https://res.cloudinary.com/dxxhtz2dw/image/upload/v1702714573/samples/nonex%20computers/slidecat/slide-2_wjrymf.png",
  },
  {
    id: 3,
    title: "6% Off For Your First Shopping",
    desc: "HP (Hewlett Packard) 840 G5|Core i5 | 8th Gen | 16GB RAM | 256GB SSD | Windows 10 Pro | From USA | 6 Months Warranty. 8th Gen | 16GB RAM | 256GB SSD | Windows 10 Pro | From USA | 6 Months Warranty.",
    cover:
      "https://res.cloudinary.com/dxxhtz2dw/image/upload/v1702714575/samples/nonex%20computers/slidecat/slide-3_ueairf.png",
  },
  {
    id: 4,
    title: "17% Off For Your First Shopping",
    desc: "HP Laptop Charger 19.5V 3.33A, 65W-Blue Pin HP LAPTOP CHARGER/ADAPTER 19.5V,3.33A WITH BLUE PIN. This laptop power supply is engineered to deliver a strong charge to any compatible laptop.",
    cover:
      "https://res.cloudinary.com/dxxhtz2dw/image/upload/v1702714577/samples/nonex%20computers/slidecat/slide-4_heibbq.png",
  },
];
export default Sdata;
