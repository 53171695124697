import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Data from "../../components/Data";
import Data2 from "../../components/shops/Sdata";
import { useHistory } from "react-router-dom";

const Search = ({ CartItem }) => {
  const { productItems } = Data;
  const { shopItems } = Data2;

  const AllProducts = productItems.concat(shopItems);

  const [searchQuery, setSearchQuery] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setIsPopupOpen(!!event.target.value);
  };

  const filteredProducts = AllProducts.filter((product) =>
    product.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  console.log("filteredProducts", filteredProducts.length);
  // fixed Header
  window.addEventListener("scroll", function () {
    const search = document.querySelector(".search");
    search.classList.toggle("active", window.scrollY >= 100);
  });

  const showPopupResults = searchQuery.length > 1;

  const history = useHistory();

  const handleClick = (e, shopItem) => {
    e.preventDefault();
    setIsPopupOpen(false);
    history.push({
      pathname: "/products-details",
      state: { shopItem },
    });
    if (!e) {
      var e = window.event;
      e.cancelBubble = true;
    }
    if (e.stopPropagation) {
      e.stopPropagation();
    }
  };

  const searchRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setIsPopupOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);
  return (
    <>
      <section className="search" ref={searchRef}>
        <div className="container c_flex position-relative">
          <div className="logo width logo-name">
            <Link to="/">
              <img
                src="https://res.cloudinary.com/dxxhtz2dw/image/upload/v1702717167/samples/nonex%20computers/logo/favicon_fnojib.png"
                style={{ width: "50px" }}
                alt=""
              />
            </Link>
            <Link to="/">
              <div className="name-wrapper">
                <h1>NONEX</h1>
                <p>computers</p>
              </div>
            </Link>
          </div>
          <div className="search-box f_flex small-screen-display-none">
            <i className="fa fa-search"></i>
            <input
              type="text"
              placeholder="Search and select..."
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <span>All Category</span>
          </div>

          <div className="icon f_flex width">
            {/* <i className="fa fa-user icon-circle small-screen-display-none"></i> */}
            <div className="whatapp-icon cart">
              <a
                href="https://api.whatsapp.com/send?phone=254702039048&text=Hello%20Nonex%20computers...,%20"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i
                  class="fa-brands fa-whatsapp icon-circle"
                  style={{ margin: 0 }}
                ></i>
                <span>?</span>
              </a>
            </div>

            <div className="cart">
              <Link to="/cart">
                <i class="fa-solid fa-heart icon-circle"></i>
                <span>{CartItem.length === 0 ? 0 : CartItem.length}</span>
              </Link>
            </div>
          </div>
          {showPopupResults && isPopupOpen && (
            <div className="position-absolute">
              <div className="popup-box">
                {filteredProducts.length === 0 && (
                  <ul>
                    <li>Sorry, We Dont Have Such Product In Our Store</li>
                  </ul>
                )}
                <ul>
                  {filteredProducts.map((product) => (
                    <>
                      <ul>
                        <li
                          onClick={(e) => handleClick(e, product)}
                          key={product.id}
                        >
                          {product.name}
                        </li>
                      </ul>
                    </>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Search;
