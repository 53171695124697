const Data = {
  productItems: [
    {
      id: 1,
      discount: 50,
      cover:
        "https://res.cloudinary.com/dchwvteoz/image/upload/v1702972483/nonex%20computers/shops/btgxpzdkoxugvapgoznc.jpg",
      cover2:
        "https://res.cloudinary.com/dchwvteoz/image/upload/v1702972483/nonex%20computers/shops/cbr132mpcizrr5pfhg3b.jpg",
      cover3:
        "https://res.cloudinary.com/dchwvteoz/image/upload/v1702972483/nonex%20computers/shops/evknsjg5xbai6dwl1tab.jpg",
      cover4:
        "https://res.cloudinary.com/dchwvteoz/image/upload/v1702972483/nonex%20computers/shops/jhxlgv1gmrkuqpgw9l2g.jpg",
      cover5:
        "https://res.cloudinary.com/dchwvteoz/image/upload/v1702972483/nonex%20computers/shops/ssu4iulshmxdcrp75n23.jpg",
      cover6:
        "https://res.cloudinary.com/dchwvteoz/image/upload/v1702972483/nonex%20computers/shops/yfcx3lrbxbxm6ooeqhbv.jpg",
      category: "laptops",
      name: "HP EliteBook 840 G5 Intel Core i5 8th Gen 8GB RAM 256GB SSD 14 Inches FHD Display ",
      price: 40000,
      wasprice: 42000,
      stock: 10,
      description:
        "Operating system: Windows 10/11 Pro – 64 Bit Edition; Memory: 8GB DDR4 – 2400MHz SDRAM (1 x 8GB) | Supports up to 32 GB; Storage: 256GB PCIe® NVMe™ M.2 SSD; Optical drive: None; Graphics Processor: Integrated: Intel® UHD Graphics 620; Processor: Intel® Core™ i5-8350U vPro™(1.7 GHz base frequency, up to 3.6 GHz with Intel® Turbo Boost Technology, 6 MB cache, 4 cores); Processor Family: 8th Generation Intel® Core™ i5 processor; Display: 14 inches (35.56 cm) diagonal FHD IPS anti-glare LED-backlit, 220 cd/m², 67% sRGB (1920 x 1080); Audio features: Bang & Olufsen, dual stereo speakers, integrated multi array microphone; Integrated Webcam: HD camera; HD IR webcam; Keyboard: HP Premium keyboard Spill-resistant with drain (thin layer of Mylar film under the keyboard) Backlit (optional); Pointing Device: Clickpad with multi-touch gestures enabled (taps enabled as default) | Microsoft Precision Touchpad default gestures supported; Battery type: 3-cell  Li-ion | Battery Capacity: 50 WHr; Battery life: Up to 14 hours; AC Adapter: Input: HP Smart 65 W External AC power adapter",
    },
    {
      id: 7,
      cover:
        "https://res.cloudinary.com/dchwvteoz/image/upload/v1703163341/nonex%20computers/shops/b8qcoydsd5i0xcrpepmo.jpg",
      cover2:
        "https://res.cloudinary.com/dchwvteoz/image/upload/v1703163342/nonex%20computers/shops/jgtwjo2l1qdiafkeihwm.jpg",
      cover3:
        "https://res.cloudinary.com/dchwvteoz/image/upload/v1703163341/nonex%20computers/shops/z92pw1uzpbwbon0kplin.jpg",
      name: "Apple 13.6 inch MacBook Air Laptop with M2 chip",
      price: "175999",
      wasprice: "179999",
      discount: "2",
      category: "laptops",
      stock: 10,
      description:
        "STRIKINGLY THIN DESIGN — The redesigned MacBook Air is more portable than ever and weighs just 2.7 pounds. The competent laptop lets you work, play or create just about anything — anywhere.; SUPERCHARGED BY M2 — Get more done faster with a next-generation 8-core CPU, up to 10-core GPU and up to 24GB of unified memory.; UP TO 18 HOURS OF BATTERY LIFE — Go all day and into the night, thanks to the power-efficient performance of the Apple M2 chip.; BIG, BEAUTIFUL DISPLAY — The 13.6-inch Liquid Retina display features over 500 nits of brightness, P3 wide colour and support for 1 billion colours for vibrant images and incredible detail.; ADVANCED CAMERA AND AUDIO — Look sharp and sound great with a 1080p FaceTime HD camera, three-mic array and four-speaker sound system with Spatial Audio.; VERSATILE CONNECTIVITY — MacBook Air features a MagSafe charging port, two Thunderbolt ports and a headphone jack.; EASY TO USE — Your Mac feels familiar from the moment you turn it on and works seamlessly with all your Apple devices. BUILT TO LAST — The all-aluminium unibody enclosure is exceptionally durable. And free software updates keep things running smooth and secure for years to come.; COMPATIBLE — All your go-to apps run lightning-fast — including Microsoft 365, Zoom, and many of your favourite iPhone and iPad apps.; COMES WITH APPLECARE WARRANTY – Every Mac comes with a one-year limited warranty and up to 90 days of complimentary technical support. Get AppleCare+ to extend your coverage.",
    },
    {
      id: 2,
      discount: 40,
      cover:
        "https://res.cloudinary.com/dchwvteoz/image/upload/v1702975127/nonex%20computers/shops/jemzfw7wfaftz40oc1qg.webp",
      cover2:
        "https://res.cloudinary.com/dchwvteoz/image/upload/v1702975127/nonex%20computers/shops/dz7plxuus0uspy603yry.webp",
      cover3:
        "https://res.cloudinary.com/dchwvteoz/image/upload/v1702975128/nonex%20computers/shops/vzvummnkiw7zxdrfmjoh.jpg",
      category: "laptops",
      name: "HP Elitebook 8440p 14-Inch (i5, 2.4GHz, 4GB Memory, 500Gb HDD, Windows 10 Professional 64-Bit), Silver",
      price: 18500,
      wasprice: 19200,
      stock: 10,
      description:
        "HP Laptop 8440p installed with Windows 10 Pro 64bit. Intel Core i5 2.4GHZ with 4GB DDR3 RAM. Laptop comes with a 500GB hard drive and DVDRW drive. It includes a 14.1″ Display, AC Adapter, Charger, and battery. The Elitebook 8440p also includes 802.11b/g/n wireless WiFi, FOUR USB ports, VGA out, speaker and headphone ports, E-Sata, firewire, SD card reader port, ExpressCard/54 slot, 10/100/1000 ethernet, and 3 month warranty.",
    },
    {
      id: 3,
      discount: 40,
      cover:
        "https://res.cloudinary.com/dchwvteoz/image/upload/v1702975935/nonex%20computers/shops/ultytqsze7zena0oohhq.jpg",
      cover2:
        "https://res.cloudinary.com/dchwvteoz/image/upload/v1702975935/nonex%20computers/shops/jngzgbqynr1ngszraqas.jpg",
      cover3:
        "https://res.cloudinary.com/dchwvteoz/image/upload/v1702975935/nonex%20computers/shops/fhsrf2oycpphhv08mebb.jpg",

      category: "laptops",
      name: "Generic 7-inch Touch Control Laptop Quad-core Office Learning RAM 8G+ ROM 128G Laptop",
      price: 37000,
      wasprice: 39000,
      stock: 10,
      description:
        "Elevate your business with an ultrathin, professional laptop that empowers users to perform at their very best. Enterprise-class features, comprehensive security, and a refined collaboration experience handle even the most demanding tasks with streamlined efficiency.; Security inside and out: Get end-to-end protection from HP’s most secure and manageable PCs.; The HP Sure Start Gen3 protects against BIOS attacks. High performance, high portability: Speed up demanding business applications and data.; Combine high-performance and long battery life with 7th Gen Intel Core processor, and PCIe Gen3 solid state drives.; Amplify performance with up to 8 GB of DDR4 memory.; Thin design, no adapters needed: Give portability a whole new meaning with an ultrathin design that doesn’t cut corners.; Starting at an incredible 18.9mm, the HP EliteBook 840 includes several full-size ports like VGA, DisplayPort, RJ-45, and enterprise docking.; See and hear them clearly: Give your online collaboration the sharpness and clarity of a face-to-face meeting with HP Audio Boost, HP Noise Cancellation software, Audio by Bang & Olufsen, and a 720p webcam.",
    },
    {
      id: 4,
      discount: 40,
      cover:
        "https://res.cloudinary.com/dchwvteoz/image/upload/v1702976551/nonex%20computers/shops/zvrf6f0umiinb48r1mnz.png",
      cover2:
        "https://res.cloudinary.com/dchwvteoz/image/upload/v1702976551/nonex%20computers/shops/oukyfe3sk31pai69epps.png",
      cover3:
        "https://res.cloudinary.com/dchwvteoz/image/upload/v1702976551/nonex%20computers/shops/zvrf6f0umiinb48r1mnz.png",
      category: "laptops",
      name: "LENOVO THINKPAD T470S (20HF0013US) LAPTOP (CORE I7 7TH GEN/8 GB/256 GB SSD/WINDOWS 10) ",
      price: 24000,
      wasprice: 24999,
      stock: 10,
      description:
        "Lenovo Thinkpad T470s (20HF0013US), (Core i7 7th Gen/8 GB/256 GB SSD/Windows 10) laptop is engineered for a long-lasting performance. This laptop offers smooth processing and comes with a stylish design. In addition to this, you can easily carry this laptop in your backpack due to its lightweight and compact design.;  You get a sturdy, full-length, and ergonomically designed TrackPoint, UltraNav, keyboard in the Lenovo Thinkpad T470s (20HF0013US) Laptop (Core i7 7th Gen/8 GB/256 GB SSD/Windows 10) so that you can working with the utmost convenience for long working hours. Also, the laptop is powered with a high-quality Li-Ion. Therefore, there zero chances of battery overheating or swelling. Hence, you can enjoy an exceptional audio-visual experience.; The Lenovo Thinkpad T470s (20HF0013US) Laptop (Core i7 7th Gen/8 GB/256 GB SSD/Windows 10) is available at Rondamo Technologies at a fair and reasonable price. Furthermore, you get a warranty of 3 months from the manufacturer from the date of purchase.Brand- Lenovo; Hard Drive Size- 256GB SSD; Model Name/Number- LENOVO THINKPAD T470; Series- THINKPAD; RAM- 8GB RAM; Processor- i7 7th gen; Screen Size- 14 INCH; Laptop Weight- 2KG to 4KG",
    },
    {
      id: 5,
      discount: 50,
      cover:
        "https://res.cloudinary.com/dchwvteoz/image/upload/v1702977245/nonex%20computers/shops/aqlxedekw7tvps4ju71b.jpg",
      cover2:
        "https://res.cloudinary.com/dchwvteoz/image/upload/v1702977245/nonex%20computers/shops/e4cya3poe7eldovrnn5a.jpg",
      cover3:
        "https://res.cloudinary.com/dchwvteoz/image/upload/v1702977245/nonex%20computers/shops/nyowgfzrdvaasz7rtmsp.jpg",
      category: "laptops",
      name: "Dell Latitude E7240 ,Core i5 ,1.9 GHz, 4GB RAM ,128GB SSD ,12.5 inch Display EXUK",
      price: 22000,
      wasprice: 23500,
      stock: 10,
      description:
        "Dell Latitude E7240 ,Core i5 ,1.9 GHz, 4GB RAM ,128GB SSD ,12.5 inch Display EXUK; Processor: Intel Core i5-4300U 1.9-2.6GHz; Memory: 4GB RAM; Storage: 128GB Solid State Drive(SSD); Graphics: Intel HD 4400; Display: 12” HD (1366 x 768) native resolution; Color: Silver",
    },
    {
      id: 6,
      discount: 51,
      cover:
        "https://res.cloudinary.com/dchwvteoz/image/upload/v1702977933/nonex%20computers/shops/tmpvljyuuges6l0pgeo3.jpg",
      cover2:
        "https://res.cloudinary.com/dchwvteoz/image/upload/v1702977933/nonex%20computers/shops/qzhd6q0qqdvnkpdlmg8p.jpg",
      cover3:
        "https://res.cloudinary.com/dchwvteoz/image/upload/v1702977933/nonex%20computers/shops/pk9ss7fpjqljg0glxtul.jpg",
      category: "laptops",
      name: "Lenovo REFURBISHED THINKPAD YOGA 11E G5, 7th Gen X360 TOUCHSCREEN, 500 GB,4 GB RAM+FREE SLEEVE",
      price: 18000,
      wasprice: 18999,
      stock: 10,
      description:
        "The ThinkPad Pen Pro for ThinkPad 11e Yoga 5th Gen with 4096 levels of pressure sensitivity, provides a natural pencil-like and paper experience when you sketch and jot down notes.  Users will also enjoy easy access to short-cut functions through two built-in customizable buttons on the base of the pen. This pen can also be conveniently stored and charged in the notebook’s pen slot.",
    },
  ],
};
export default Data;
